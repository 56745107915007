import React, { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment';

import { MoreOutlined, LinkOutlined } from '@ant-design/icons';
import { Table, Tooltip, Popconfirm, message } from 'antd';


export default function TruckEventSubTable(props){

    const [data, setData] = useState([]);
    const [loadingRecords, setLoadingRecords] = useState(false);

    useEffect(() => {
        fetchTripEvents(props.tripId)
    }, [])

    const fetchTripEvents = (tripId) => {
        setLoadingRecords(true);
        axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips/' + tripId + '/events')
            .then(response => {
                if (response.status === 200){
                    setData(response.data.events)
                } else {
                    console.log(response);
                    message.error("Could not load events for trip " + tripId + "...")
                }
                setLoadingRecords(false);
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load events for trip " + tripId + "...")
                setLoadingRecords(false);
            });
    }


    const columns = [
        { title: 'Created', dataIndex: 'created', key: 'created', width: 180, 
            render: val => !!val ? moment(val).format('DD/MM/YYYY HH:mm:ss') : val,
            sorter: (a, b) => moment(a.created) - moment(b.created),
            sortDirections: ['ascend', 'descend'],
        },
        { title: 'ID', dataIndex: 'id', key: 'id', width: 85},      
        { title: 'Message Type', dataIndex: 'message_type', key: 'message_type'},
        { title: 'Topic', dataIndex: 'topic', key: 'topic'},
        { title: 'Tractor ID', dataIndex: 'tag', key: 'tag'},
        { title: 'Payload', dataIndex: 'payload', key: 'payload', width: 400, 
            render: (val, _record) =>
                <React.Fragment>
                    {JSON.stringify(val).slice(0,50) + '...'}
                    {JSON.stringify(val).length > 50 &&
                    <Popconfirm
                        cancelText="Hide"
                        overlayInnerStyle={{maxWidth:600}}
                        okText="Copy"
                        icon={<LinkOutlined />}
                        onConfirm={() => {
                            window.navigator.clipboard.writeText(JSON.stringify(val));
                            message.success("Payload copied to clipboard!");
                        }}
                        title={JSON.stringify(val, null, 2)}
                        placement="left"
                    >
                        <Tooltip title="View payload"><MoreOutlined /></Tooltip>
                    </Popconfirm>}
                </React.Fragment>
        },
    ];


    return <Table
        sticky
        columns={columns}
        loading={loadingRecords}
        pagination={false}
        // pagination={{ pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true, size: "small", hideOnSinglePage: false, style:{marginBottom: 0} }}
        bordered={true}
        // expandable={{
        //     expandedRowRender: record => <ViolationsSubTable violationRecord={record} {...props}/>,
        //     rowExpandable: record => record.violations > 0
        // }}
        rowKey="id"
        dataSource={data}
        size="small"
        scroll={{ y: 0.5 * window.innerHeight, scrollToFirstRowOnChange: true }}
        style={{marginBottom: 12}}
    />;
}
