import React, { useState } from "react";

import { Button, message } from 'antd';
import { LogoutOutlined } from "@ant-design/icons";


export default function LogoutButton(){

    const [loggingOut, setLoggingOut] = useState(false)

    const logout = () => {
        try {
            setLoggingOut(true)
            // Clear local storage
            localStorage.removeItem('token');
            // Reload webpage
            window.location.reload();
        } catch(error) {
            console.log(error);
            message.error("Could not log out...")
        } finally {
            setLoggingOut(false)
        }
    }

    return (
        <Button 
            type="primary" 
            size="large" 
            icon={<LogoutOutlined />} 
            onClick={() => logout()}
            style={{marginRight: 4}}
            loading={loggingOut}
        >
            Logout
        </Button>
    );
}

