import React from "react";
import moment from 'moment';

import { MoreOutlined, LinkOutlined } from '@ant-design/icons';
import { Tooltip, Popconfirm, message } from 'antd';

import ProTable from '@ant-design/pro-table';


export default function EventTable(props){

    const columns = [
        { title: 'Created', dataIndex: 'created', key: 'created', width: 120,
            render: val => !!val ? moment(val).format('DD/MM/YYYY HH:mm:ss') : val,
            sorter: (a, b) => moment(a.created) - moment(b.created),
            sortDirections: ['ascend', 'descend'],
        },
        { title: 'ID', dataIndex: 'id', key: 'id', width: 80},
        { title: 'Tractor ID', dataIndex: 'tag', key: 'tag', width: 80},
        { title: 'Message Type', dataIndex: 'message_type', key: 'message_type', width: 110},
        { title: 'Topic', dataIndex: 'topic', key: 'topic', width: 200},
        { title: 'Trip ID', dataIndex: 'trip_id', key: 'trip_id', width: 120},
        { title: 'Payload', dataIndex: 'payload', key: 'payload', width: 230,
            render: (val, _record) => 
                <React.Fragment>
                    {JSON.stringify(val).slice(0,35) + '...'}
                    {JSON.stringify(val).length > 35 &&
                    <Popconfirm
                        cancelText="Hide"
                        overlayInnerStyle={{maxWidth:600}}
                        okText="Copy"
                        icon={<LinkOutlined />}
                        onConfirm={() => {
                            window.navigator.clipboard.writeText(JSON.stringify(val));
                            message.success("Payload copied to clipboard!");
                        }}
                        title={JSON.stringify(val, null, 2)}
                        placement="left"
                    >
                        <Tooltip title="View payload"><MoreOutlined /></Tooltip>
                    </Popconfirm>}
                </React.Fragment>
        },
    ];


    return <ProTable
        columns={columns}
        loading={props.loading}
        pagination={{ pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true, size: "small", hideOnSinglePage: false, style:{marginBottom: 0} }}
        bordered={true}
        // expandable={{
        //     expandedRowRender: record => <ViolationsSubTable violationRecord={record} {...props}/>,
        //     rowExpandable: record => record.violations > 0
        // }}
        rowKey="id"
        dataSource={props.data}
        scroll={{ y: props.scroll }}
        search={false}
        options={{'reload': () => props.fetchData()}}
    />;
}
