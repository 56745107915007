import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import enUSIntl from 'antd/lib/locale/en_US';

import { Layout, message, Typography, Row, Col, Spin, Menu, ConfigProvider} from 'antd';
import { CalculatorFilled, CalendarFilled, CarOutlined, GlobalOutlined, TagOutlined, NodeIndexOutlined } from "@ant-design/icons";

import EventTable from "./Events/EventTable";
import TripTable from "./Trips/TripTable";
import TractorTable from "./Tractors/TractorTable";
import TrailerTable from "./Trailers/TrailerTable";
import SingleTrip from "./SingleTrip/SingleTrip";
import Map from "./Map/Map";
import DataZoom from "./DataZoom"
import LogoutButton from "./LogoutButton"


const { Header, Content, Sider } = Layout;
const { Title } = Typography;

const appBarHeight = 68;
const sidebarWidth = 140;

const AngeLogo = <img src='./images/AngePurpleSmooth.png' style={{maxWidth:50, maxHeight:50, marginTop: 5}} />

function useWindowSize() {
    const [height, setHeight] = useState([window.innerHeight - 1, window.outerHeight - 1]);
    useLayoutEffect(() => {
        function updateSize() {
            setHeight([window.innerHeight - 1, window.outerHeight - 1]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return height;
}

function useInitialPath() {
    const [initialPath, setInitialPath] = useState('');
    const location = useLocation();
    // const navigate = useNavigate();
    useEffect(() => {
        // console.log(location)
        let initPath = location.pathname
        if (!initPath.endsWith('/')) initPath = initPath + '/'
        setInitialPath(initPath)
    },[])
    return initialPath
}

// Custom hook to used to prevent on mount execution with useEffects with dependencies
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
    }, deps);
  };


export default function App(){

    const navigate = useNavigate();
    const location = useLocation();
    // const basePath = useInitialPath();
    const currentLocation = (location.pathname).split('/')[1]

    const [initializing, setInitializing] = useState(true)
    const [loading, setLoading] = useState({"tractors": false, "events": false, "trips": false, "trailers": false})

    // style state - keep track of viewport height
    const [intFrameHeight, intOuterFrameHeight] = useWindowSize();

    const [trucks, setTrucks] = useState([]);
    const [events, setEvents] = useState([]);
    const [trips, setTrips] = useState([]);
    const [trailers, setTrailers] = useState([]);

    // Data zoom
    const [dataZoomTrucksLookup, setDataZoomTrucksLookup] = useState([])
    const [dataZoomFilters, setDataZoomFilters] = useState({tractors: [], date: null})

    // Starting the application
    useEffect(async () => {
        await Promise.all([fetchTrucks(),fetchEvents(),fetchTrailers(),fetchTrips()]).then(e => setInitializing(false))
    }, [])

    // When base path is established, load data
    // useEffect(() => {
    //     fetchTrucks()
    //     navigate(basePath + "tractors")
    // }, [basePath])

    // Watching data zoom filters
    useDidMountEffect(() => {
        fetchTrucks(); 
        fetchEvents();
        fetchTrailers(); 
        fetchTrips(); 
    }, [dataZoomFilters])

    const fetchTrucks = async () => {
        setLoading(currLoading => {return {...currLoading, "tractors": true}})
        // Handle data zoom params
        let qparams = {}
        if (dataZoomFilters.date) qparams["date"] = dataZoomFilters.date
        if (dataZoomFilters.tractors.length > 0) qparams["tag"] = dataZoomFilters.tractors
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trucks', {params: qparams })
            .then(response => {
                if (response.status === 200){
                    // TODO use a lookup endpoint, for now a trick to generate tractor lookup
                    if (trucks.length === 0) {
                        var tractorList = []
                        response.data.trucks.map(track => tractorList.push({value: track.tag}))
                        setDataZoomTrucksLookup(tractorList)
                        // setDataZoomTrucksLookup(response.data.trucks.map(track => {{value: track.tag}}))
                    }
                    setTrucks(response.data.trucks)
                } else {
                    console.log(response);
                    message.error("Could not load tractors...")
                }
                setLoading(currLoading => { return {...currLoading, "tractors": false}})
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load tractors...")
                setLoading(currLoading => { return {...currLoading, "tractors": false}})
            });
    }

    const fetchEvents = async () => {
        setLoading(currLoading => { return {...currLoading, "events": true}})
        // Handle data zoom params
        let qparams = {}
        if (dataZoomFilters.date) qparams["date"] = dataZoomFilters.date
        if (dataZoomFilters.tractors.length > 0) qparams["tag"] = dataZoomFilters.tractors
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/events', {params: qparams})
            .then(response => {
                if (response.status === 200){
                    setEvents(response.data.events)
                } else {
                    console.log(response);
                    message.error("Could not load events...")
                }
                setLoading(currLoading => { return {...currLoading, "events": false}})
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load events...")
                setLoading(currLoading => { return {...currLoading, "events": false}})
            });
    }

    const fetchTrips = async () => {
        setLoading(currLoading => { return {...currLoading, "trips": true}})
        // Handle data zoom params
        let qparams = {}
        if (dataZoomFilters.date) qparams["date"] = dataZoomFilters.date
        if (dataZoomFilters.tractors.length > 0) qparams["tag"] = dataZoomFilters.tractors
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trips', {params: qparams})
            .then(response => {
                if (response.status === 200){
                    setTrips(response.data.trips)
                } else {
                    console.log(response);
                    message.error("Could not load trips...")
                }
                setLoading(currLoading => { return {...currLoading, "trips": false}})
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load trips...")
                setLoading(currLoading => { return {...currLoading, "trips": false}})
            });
    }

    const fetchTrailers = async () => {
        setLoading(currLoading => { return {...currLoading, "trailers": true}})
        // Handle data zoom params
        let qparams = {}
        if (dataZoomFilters.date) qparams["date"] = dataZoomFilters.date
        if (dataZoomFilters.tractors.length > 0) qparams["tag"] = dataZoomFilters.tractors
        await axios.get(window.vtrack_config.REACT_APP_API_URL + '/trailers', {params: qparams})
            .then(response => {
                if (response.status === 200){
                    setTrailers(response.data.trailers)
                } else {
                    console.log(response);
                    message.error("Could not load trailers...")
                }
                setLoading(currLoading => { return {...currLoading, "trailers": false}})
            })
            .catch(error => {
                console.log(error);
                message.error("Could not load trailers...")
                setLoading(currLoading => { return {...currLoading, "trailers": false}})
            });
    }

    const handleMenuSelection = e => {
        navigate(e.key)
        if (e.key === "tractors" && trucks.length === 0) fetchTrucks()
        if (e.key === 'events' && events.length === 0) fetchEvents()
        if (e.key === 'trips' && trips.length === 0) fetchTrips()
        if (e.key === 'trailers' && trailers.length === 0) fetchTrailers()
        if (e.key === 'map'){
            if(trucks.length === 0) fetchTrucks();
            if(trailers.length === 0) fetchTrailers();
            if(trips.length === 0) fetchTrips();
        }
    }

    const applyDataZoom = (date, tractors) => {
        setDataZoomFilters({"date": date, "tractors": tractors})
    }

    const menuItems = [
        { label: <a href="#/tractors">Tractors</a>, key: 'tractors', icon: <CarOutlined />, style:{paddingRight: 4} },
        { label: <a href="#/events">Events</a>, key: 'events', icon: <CalendarFilled />, style:{paddingRight: 4} },
        { label: <a href="#/trips">Trips</a>, key: 'trips', icon: <CalculatorFilled />, style:{paddingRight: 4} },
        { label: <a href="#/singletrip">Single Trip</a>, key: 'singletrip', icon: <NodeIndexOutlined />, style:{paddingRight: 4} },
        { label: <a href="#/trailers">Trailers</a>, key: 'trailers', icon: <TagOutlined />, style:{paddingRight: 4} },
        { label: <a href="#/map">Map</a>, key: 'map', icon: <GlobalOutlined />, style:{paddingRight: 4} },
    ]

    return (
        <ConfigProvider locale={enUSIntl}>
        <React.Fragment>
        {initializing
            ?
                <span style={{textAlign: "center", display: "block", paddingTop: 0.3*intFrameHeight}}>
                    <Spin spinning={initializing} size="large" tip="Initializing VTrack UI..." style={{marginTop: 0}}>
                        <img src='./images/AngePurpleSmooth.png' style={{minWidth:200, minHeight:200}} />
                    </Spin>
                </span>
            :
                <Layout style={{margin: -8}}>
                    <Header style={{ height: appBarHeight, width: '100%', background: "#fff", borderBottom:"1px solid #ccc", padding: 2}}>
                        <Row gutter={0} justify="space-between">
                            <Col>
                                <Row>
                                    <Col style={{marginLeft: 4}}>
                                        {AngeLogo}
                                    </Col>
                                    <Col style={{marginLeft: 8}}>
                                        <Title level={2} style={{color: "#54146b", marginTop: 10, marginBottom: 0}}>
                                            VTrack UI
                                        </Title>
                                        {/* <Title level={4} style={{color: "#54146b", marginTop: -6}}>
                                            UI
                                        </Title> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <DataZoom 
                                    applyDataZoom={applyDataZoom} 
                                    dataZoomTrucksLookup={dataZoomTrucksLookup}
                                    dataZoomDate={dataZoomFilters.date}
                                    dataZoomTrucks={dataZoomFilters.tractors}
                                />
                                {window.vtrack_config.USE_API_LOGIN && <LogoutButton />}
                            </Col>
                        </Row>
                    </Header>
                    <Layout style={{background: "#222"}}>
                        <Sider theme="light" width={sidebarWidth} style={{height: 'calc(100vh - 68px)'}}>
                            <Menu selectedKeys={[currentLocation]}
                                defaultSelectedKeys={["tractors"]}
                                onClick={handleMenuSelection}
                                items={menuItems}
                            />
                        </Sider>
                        <Layout style={{borderLeft:"1px solid #ccc", height: 'calc(100vh - 68px)', background: "#fff"}}>
                            <Content style={{overflowY: 'auto'}}>
                                <Routes>
                                        <Route path={"tractors"} element={
                                            <TractorTable
                                                data={trucks}
                                                scroll={intFrameHeight - 3.4*appBarHeight }
                                                loading={loading.tractors}
                                                fetchData={fetchTrucks}
                                                // trucksLoading={trucksLoading}
                                                dataZoomDate={dataZoomFilters.date}
                                            />} 
                                        />
                                        <Route path={"events"} element={
                                            <EventTable
                                                data={events}
                                                scroll={intFrameHeight - 3.4*appBarHeight }
                                                loading={loading.events}
                                                fetchData={fetchEvents}
                                            />} 
                                        />
                                        <Route path={"trips"} element={
                                            <TripTable
                                                data={trips}
                                                scroll={intFrameHeight - 3.4*appBarHeight }
                                                loading={loading.trips}
                                                fetchData={fetchTrips}
                                            />} 
                                        />
                                        <Route path={"singletrip"} element={
                                            <SingleTrip
                                                trips={trips}
                                                intFrameHeight={intFrameHeight}
                                                appBarHeight={appBarHeight}
                                            />} 
                                        >
                                            <Route path=":tripId" element={
                                                <SingleTrip
                                                    trips={trips}
                                                    intFrameHeight={intFrameHeight}
                                                    appBarHeight={appBarHeight}
                                                />} 
                                                />
                                            </Route>
                                        <Route path={"trailers"} element={
                                            <TrailerTable
                                                data={trailers}
                                                scroll={intFrameHeight - 3.4*appBarHeight }
                                                loading={loading.trailers}
                                                fetchData={fetchTrailers}
                                            />} 
                                        />
                                        <Route path={"map"} element={
                                            <Map
                                                trailers={trailers}
                                                tractors={trucks}
                                                trips={trips}
                                                dataZoomTractors={dataZoomFilters.tractors}
                                                loading={loading.trailers || loading.tractors || loading.trips}
                                                fetchTractors={fetchTrucks}
                                                fetchTrailers={fetchTrailers}
                                                fetchTrips={fetchTrips}
                                            />} 
                                        />
                                    <Route
                                        path="*"
                                        element={<Navigate to={"tractors"} replace />}
                                    />
                                </Routes>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
        }
        </React.Fragment>
        </ConfigProvider>
    );
}

