import React from "react";
import moment from 'moment';

import { MoreOutlined, LinkOutlined } from '@ant-design/icons';
import { Table, Tooltip, Popconfirm, message } from 'antd';


export default function SingleTripTable(props){

    const columns = [
        { title: 'Created', dataIndex: 'created', key: 'created', width: 160,
            render: val => !!val ? moment(val).format('DD/MM/YYYY HH:mm:ss') : val,
            sorter: (a, b) => moment(a.created) - moment(b.created),
            sortDirections: ['ascend', 'descend'],
        },
        { title: 'ID', dataIndex: 'id', key: 'id', width: 85},      
        { title: 'Message Type', dataIndex: 'message_type', width: 120},
        { title: 'Topic', dataIndex: 'topic', key: 'topic', width: 220},
        { title: 'Tractor ID', dataIndex: 'tag', key: 'tag', width: 100},
        { title: 'Payload', dataIndex: 'payload', key: 'payload', width: 400, 
            render: (val, record) =>
                <React.Fragment>
                    {JSON.stringify(val).slice(0,55) + '...'}
                    {JSON.stringify(val).length > 55 &&
                    <Popconfirm
                        cancelText="Hide"
                        overlayInnerStyle={{maxWidth:600}}
                        okText="Copy"
                        icon={<LinkOutlined />}
                        onConfirm={() => {
                            window.navigator.clipboard.writeText(JSON.stringify(val));
                            message.success("Payload copied to clipboard!");
                        }}
                        title={JSON.stringify(val, null, 2)}
                        placement="left"
                    >
                        <Tooltip title="View payload"><MoreOutlined /></Tooltip>
                    </Popconfirm>}
                </React.Fragment>
        },
    ];


    return (
        <Table
            columns={columns}
            loading={props.loading}
            pagination={{ pageSizeOptions: ['50', '100'], showSizeChanger: true, size: "small", hideOnSinglePage: false, style:{marginBottom: 0} }}
            bordered={true}
            rowKey="id"
            dataSource={props.data}
            size="small"
            scroll={{y: props.scroll - 44}}
        />
    )

}
