
export const singleTripLink = (tripId) => {
    return <a href={"#/singletrip/" + tripId}>{tripId}</a>
}

export function authHeader() {
    // Do not append jwt token when not using api auth
    if (!window.vtrack_config.USE_API_LOGIN) return null;
    const token = localStorage.getItem('token');
    if (token) {
        return 'Bearer ' + token ;
    } else {
        return null;
    }
}
