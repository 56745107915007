import React, { useState, useEffect } from "react";
import moment from 'moment';

import { Typography, Row, Col, Button, Modal, Select, DatePicker} from 'antd';
import { SettingOutlined } from "@ant-design/icons";
const { Text } = Typography;


export default function DataZoom(props){

    // Data zoom
    const [dataZoomOpen, setDataZoomOpen] = useState(false)
    const [dataZoomDateTemp, setDataZoomDateTemp] = useState(props.dataZoomDate)
    const [dataZoomTrucksTemp, setDataZoomTrucksTemp] = useState(props.dataZoomTrucks)

    useEffect(() => {
        setDataZoomDateTemp(props.dataZoomDate)
        setDataZoomTrucksTemp(props.dataZoomTrucks)
    }, [props.dataZoomDate, props.dataZoomTrucks])

    const onClose = () => {
        setDataZoomDateTemp(props.dataZoomDate)
        setDataZoomTrucksTemp(props.dataZoomTrucks)
        setDataZoomOpen(false)
    }

    return (
        <React.Fragment>
            <Button 
                type="primary" 
                size="large" 
                icon={<SettingOutlined />} 
                onClick={() => setDataZoomOpen(true)}
                style={{marginRight: 4}}
            >
                Data Zoom
            </Button>
            <Modal
                title="Data Zoom"
                open={dataZoomOpen}
                onOk={onClose}
                onCancel={onClose}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {props.applyDataZoom(dataZoomDateTemp, dataZoomTrucksTemp); setDataZoomOpen(false)}}>
                        Apply settings
                    </Button>
                ]}
                style={{}}
            >
                <Row gutter={4} type="flex">
                    <Col span={24}>
                        <Text strong style={{paddingRight:6, paddingLeft:3}}>Date</Text>
                        <DatePicker
                            showNow={false}
                            disabledDate={e => e > moment.now()}
                            style={{width: "100%"}}
                            showTime={{ format: 'HH:mm:ss' }}
                            format="DD/MM/YYYY HH:mm:ss"
                            onChange={val => {if (val == null){setDataZoomDateTemp(null)} }}
                            onOk={(value) => {setDataZoomDateTemp(moment(value.toString()).format("YYYY-MM-DDTHH:mm:ss"))}} />
                    </Col>
                    <Col span={24}>
                        <Text strong style={{paddingRight:6, paddingLeft:3}}>Tractors</Text>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: '100%'}}
                            value={dataZoomTrucksTemp}
                            options={props.dataZoomTrucksLookup}
                            onChange={val => {setDataZoomTrucksTemp(val)}}
                            maxTagCount="responsive"
                            placeholder="Select tractors..."
                        />
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    );
}

