import React from "react";
import moment from 'moment';

import { Typography, Tag } from 'antd';
const { Text } = Typography;

import ProTable from '@ant-design/pro-table';

import {singleTripLink} from "../Helpers";


export default function TrailerTable(props){

    const columns = [
        { title: 'Trailer #', width: 100, dataIndex: 'trailer_number'},
        // { title: 'Created', width: 180, dataIndex: 'created',
        //     render: val => !!val ? moment(val).format('DD/MM/YYYY HH:mm:ss') : val,
        //     sorter: (a, b) => moment(a.created) - moment(b.created),
        //     sortDirections: ['ascend', 'descend'],
        // },
        // { title: 'Updated', width: 180, dataIndex: 'updated',
        //     render: val => !!val ? moment(val).format('DD/MM/YYYY HH:mm:ss') : val,
        //     sorter: (a, b) => moment(a.updated) - moment(b.updated),
        //     sortDirections: ['ascend', 'descend'],
        // },
        { title: 'Created/Updated', dataIndex: 'created_updated', width: 220, render: (_val, record) =>
            <>
                <Text strong>Created: </Text><Text>{!!record.created ? moment(record.created).format('DD/MM/YYYY HH:mm:ss') : record.created}</Text><br/>
                <Text strong>Updated: </Text><Text>{!!record.updated ? moment(record.updated).format('DD/MM/YYYY HH:mm:ss') : record.updated}</Text>
            </>
        },
        { title: 'Message ID', width: 100, dataIndex: 'message_id'},
        { title: 'Status', width: 100, render: (_val, record) => 
            <>
                {(record.deck_id === "" || record.deck_id == null) 
                    ? <Tag color="#666">ON YARD</Tag>
                    : <Tag color="#4287f5">ON VESSEL</Tag>
                }
            </>
        },
        { title: 'Deck', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>ID: </Text><Text>{record.deck_id}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.deck_time ? moment(record.deck_time).format('DD/MM/YYYY HH:mm:ss') : record.deck_time}</Text>
                </React.Fragment>
        },
        { title: 'Trailer Position', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Latitude: </Text><Text>{record.position_latitude}</Text><br/>
                    <Text strong>Longitude: </Text><Text>{record.position_longitude}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.position_time ? moment(record.position_time).format('DD/MM/YYYY HH:mm:ss') : record.position_time}</Text>
                </React.Fragment>
        },
        { title: 'Tractor', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{record.truck_tag}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.truck_time ? moment(record.truck_time).format('DD/MM/YYYY HH:mm:ss') : record.truck_time}</Text>
                </React.Fragment>
        },
        { title: 'Trip', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Active: </Text><Text>{record.trip_active ? "True" : "False"}</Text><br/>
                    <Text strong>ID: </Text><Text>{singleTripLink(record.trip_id)}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.trip_time ? moment(record.trip_time).format('DD/MM/YYYY HH:mm:ss') : record.trip_time}</Text>
                </React.Fragment>
        },
    ];


    return <ProTable
        columns={columns}
        loading={props.loading}
        pagination={{ pageSizeOptions: ['10', '20', '50', '100'], showSizeChanger: true, size: "small", hideOnSinglePage: false, style:{marginBottom: 0} }}
        bordered={true}
        rowKey="trailer_number"
        dataSource={props.data}
        scroll={{ y: props.scroll }}
        search={false}
        options={{'reload': () => props.fetchData()}}
    />;
}
