import React, { useState, useEffect } from "react";
import moment from 'moment';
import $ from 'jquery'
import 'leaflet/dist/leaflet.css';

import { MapContainer, TileLayer, Popup, Circle, ImageOverlay, Polyline } from 'react-leaflet'
import { ReloadOutlined, FilterOutlined, GlobalOutlined } from "@ant-design/icons";
import { Button, Typography, Select, Row, Col, Radio } from 'antd';
const {Text} = Typography;

// Map controls
const position = [51.9054, 4.3612]
const mapBounds = [[51.901503, 4.354982],[51.910094, 4.368867]]
// Should preload the image
new Image().src = './images/BigMap.png';
const dockMap = <ImageOverlay id="map" bounds={mapBounds} url='./images/BigMap.png' opacity={0.5}/>


export default function Map(props){

    const [trailerPositions, setTrailerPositions] = useState([]);
    const [tractorPositions, setTractorPositions] = useState([]);
    const [tripPositions, setTripPositions] = useState([]);

    // Filters
    const [filtersVisible, toggleFilters] = useState(true)
    const [tractorFilter, setTractorFilter] = useState([]);
    const [trailerFilter, setTrailerFilter] = useState([]);
    const [tripFilter, setTripFilter] = useState([]);
    const [tripTractorFilter, setTripTractorFilter] = useState([]);

    // Lookups for filters
    const [tractorLookup, setTractorLookup] = useState([]);
    const [trailerLookup, setTrailerLookup] = useState([]);
    const [tripLookup, setTripLookup] = useState([]);

    // Map switch
    const [showMap, toggleMap] = useState(false);

    // Mode
    const [mode, setMode] = useState("equipment")


    useEffect(() => {
        let allTrailers = []
        let newTrailers = []
        props.trailers.map(row => {
            if (row.position_latitude && row.position_longitude){
                // Apply filters
                if (tripFilter.length == 0 && (trailerFilter.length == 0 || trailerFilter.includes(row.trailer_number))) {
                    newTrailers.push({
                        "id": row.trailer_number, 
                        "latitude": row.position_latitude,
                        "longitude": row.position_longitude,
                        "time": row.position_time
                    })
                }
                allTrailers.push({value: row.trailer_number})
            }
        })
        setTrailerPositions(newTrailers)
        setTrailerLookup(allTrailers)
    }, [props.trailers])

    useEffect(() => {
        let allTractors = []
        let newTractors = []
        // Data could change due to new data zoom tractors. Intersect them with currect tractor filter.
        // Find out new tractor filter value
        let newTractorFilter = props.dataZoomTractors.length == 0 ? tractorFilter : tractorFilter.filter(tractor => props.dataZoomTractors.includes(tractor))
        props.tractors.map(row => {
            if (row.position_latitude && row.position_longitude){
                // Apply filters (newTractorFilter === tractorFilter will redner here instead of in filter update)
                if (newTractorFilter === tractorFilter && tripFilter.length == 0 && (newTractorFilter.length == 0 || newTractorFilter.includes(row.tag))) {
                    newTractors.push({
                        "id": row.tag, 
                        "latitude": row.position_latitude,
                        "longitude": row.position_longitude,
                        "time": row.position_time
                    })
                }
                allTractors.push({value: row.tag})
            }
        })
        setTractorFilter(newTractorFilter) // if current filter != new filter rerender will happen here
        setTractorPositions(newTractors)
        setTractorLookup(allTractors)
    }, [props.tractors])

    useEffect(() => {
        let allTrips = []
        let newTrips = []
        props.trips.map(row => {
            if (row.pickup_latitude && row.pickup_longitude){
                row.path = row.path.map(point => [point.loc_lat, point.loc_lon])
                // Apply filters
                if ((tripFilter.length === 0 || tripFilter.includes(row.trip_id)) && (tripTractorFilter.length === 0 || tripTractorFilter.includes(row.tag))) newTrips.push(row)
                allTrips.push({value: row.trip_id})
            }
        })
        setTripPositions(newTrips)
        setTripLookup(allTrips)
    }, [props.trips])

    // Watch and handle filter changes
    useEffect(() => {
        handleFilter("tractor")
    }, [tractorFilter])

    useEffect(() => {
        handleFilter("trailer")
    }, [trailerFilter])

    useEffect(() => {
        handleFilter("trip")
    }, [tripFilter])

    useEffect(() => {
        handleFilter("trip_tractor")
    }, [tripTractorFilter])

    function handleFilter(category){
        if (category === "tractor"){
            let newTractors = []
            props.tractors.map(row => {
                // check if coordinates exist
                if (!row.position_latitude || !row.position_longitude) return
                // Apply filters
                if (tractorFilter.length == 0 || tractorFilter.includes(row.tag)) {
                    newTractors.push({
                        "id": row.tag, 
                        "latitude": row.position_latitude,
                        "longitude": row.position_longitude,
                        "time": row.position_time
                    })
                }
            })
            setTractorPositions(newTractors)
        }
        if (category == "trailer"){
            let newTrailers = []
            props.trailers.map(row => {
                if (!row.position_latitude || !row.position_longitude) return
                // Apply filters
                if (trailerFilter.length == 0 || trailerFilter.includes(row.trailer_number)) {
                    newTrailers.push({
                        "id": row.trailer_number, 
                        "latitude": row.position_latitude,
                        "longitude": row.position_longitude,
                        "time": row.position_time
                    })
                }
            })
            setTrailerPositions(newTrailers)
        }
        if (category === "trip"){
            let newTrips = []
            props.trips.map(row => {
                if (!row.pickup_latitude || !row.pickup_longitude) return
                if ((tripFilter.length === 0 || tripFilter.includes(row.trip_id)) && (tripTractorFilter.length === 0 || tripTractorFilter.includes(row.tag))) newTrips.push(row)
            })
            setTripPositions(newTrips)
        }
        if (category === "trip_tractor"){
            // handle Trips associated with selected tractors
            let tempTripsLookup = []
            props.trips.map(row => {
                if (tripTractorFilter.length === 0 || tripTractorFilter.includes(row.tag)) {
                    tempTripsLookup.push(row.trip_id)
                }
            })
            setTripLookup(tempTripsLookup.map(e => {return {value: e}}))
            setTripFilter(filter => {
                let newFilter = filter.filter(id => tempTripsLookup.includes(id))
                return newFilter
            })
        }
    }

    const toggleMapButton = 
        <Button 
            icon={<GlobalOutlined />}
            style={{width: 145}}
            onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                toggleMap(show => {
                    return !show
                })
            }}
        >
            {showMap ? "Hide satelite" : "Show satelite"}
        </Button>

    const fetchButton = 
        <Button 
            style={{width: 145}}
            onClick={() => {props.fetchTrailers(); props.fetchTractors(); props.fetchTrips();}} 
            loading={props.loading}
            icon={<ReloadOutlined />}> 
                Fetch data
        </Button>

    const selectTractorsBox = 
        <Select
            mode="multiple"
            allowClear
            style={{width: "100%"}}
            value={tractorFilter}
            options={tractorLookup}
            onChange={val => setTractorFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all tractors..."
        />

    const selectTrailersBox = 
        <Select
            mode="multiple"
            style={{width: "100%"}}
            allowClear
            value={trailerFilter}
            options={trailerLookup}
            onChange={val => setTrailerFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all trailers..."
        />

        const selectTripTractorsBox = 
            <Select
                mode="multiple"
                allowClear
                style={{width: "100%"}}
                value={tripTractorFilter}
                options={tractorLookup}
                onChange={val => setTripTractorFilter(val)}
                maxTagCount="responsive"
                placeholder="Selecting all tractors..."
            />

    const selectTripsBox = 
        <Select
            mode="multiple"
            allowClear
            style={{width: "100%"}}
            value={tripFilter}
            options={tripLookup}
            onChange={val => setTripFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all trips for selected tractors..."
        />

    const toggleFiltersButton = 
        <Button 
            type="primary" 
            icon={<FilterOutlined />}
            onClick={() => {showhide('filters')}}
            style={{width: 145}}
        >
            {filtersVisible ? "Hide filters" : "Show filters"}
        </Button>

    const filtersBox = 
        <div id="filtersbox" style={{zIndex: 998, position: "absolute", top: 4, right: 4, width: 453, padding: 4}}>
            <Row gutter={4} type="flex" justify="space-between">
                <Col>
                    {toggleFiltersButton}
                </Col>
                <Col>
                    {toggleMapButton}
                </Col>
                <Col>
                    {fetchButton}
                </Col>
            </Row>
            <Row type="flex">
                <Col span={24}>
                    <div id="filters">
                        <Row>
                            <Col span={24} style={{marginTop: 4}}>
                                <Radio.Group onChange={e => setMode(e.target.value)} buttonStyle="solid" defaultValue="equipment" style={{width: '100%'}}>
                                    <Radio value="equipment"><Text strong>Current equipment location</Text></Radio>
                                    <Radio value="trip"><Text strong>Trip history</Text></Radio>
                                </Radio.Group>
                            </Col>
                            {mode === "equipment" && <Col span={24}>
                                <Text strong style={{paddingRight:6, paddingLeft:3}}>Tractors</Text>
                                {selectTractorsBox}
                            </Col>}
                            {mode === "equipment" && <Col span={24}>
                                <Text strong style={{paddingRight:6, paddingLeft:3}}>Trailers</Text>
                                {selectTrailersBox}
                            </Col>}
                            {mode === "trip" && <Col span={24}>
                                <Text strong style={{paddingRight:6, paddingLeft:3}}>Tractors</Text>
                                {selectTripTractorsBox}
                            </Col>}
                            {mode === "trip" && <Col span={24}>
                                <Text strong style={{paddingRight:6, paddingLeft:3}}>Trips</Text>
                                {selectTripsBox}
                            </Col>}
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>

    // JQuery functions
    function showhide(id) {
        if (document.getElementById) {
            var divid = document.getElementById(id);
            // var divs = document.getElementsByClassName("hideable");
            if (filtersVisible) {
                $(divid).fadeOut("slow");
            } else {
                $(divid).fadeIn("slow");
            }
            toggleFilters(!filtersVisible)
        }
        return false;
    }

    // function modifyFilterBackground(show){
    //     var divid = document.getElementById("map_container")
    //     console.log(divid)
    //     if (show){
    //         $(divid).attr({"opacity": 0.5});
    //     } else {
    //         $(divid).attr({"opacity": 0.0});
    //     }
    //     return false;
    // }


    return (
        <MapContainer
            id="map_container"
            center={position}
            zoom={16}
            scrollWheelZoom={true}
            style={{height: '100%', width: '100%', minWidth: 500}}
        >
            {filtersBox}
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ImageOverlay id="map_sat" bounds={mapBounds} url='./images/BigMap.png' opacity={showMap ? 0.5 : 0.0}/>
            {mode === "equipment" && tractorPositions.map(trailer => 
                <Circle key={trailer.id} center={[trailer.latitude, trailer.longitude]} radius={3} color="#222">
                    <Popup closeButton={false}>
                        <Text strong>Tractor: </Text>{trailer.id}<br/>
                        <Text strong>Time: </Text>{!!trailer.time && moment(trailer.time).format('DD/MM/YYYY HH:mm:ss')} <br/>
                        <Text strong>Lat: </Text>{trailer.latitude} <Text strong>Long: </Text>{trailer.longitude}
                    </Popup>
                </Circle >
            )}
            {mode === "equipment" && trailerPositions.map(trailer => 
                <Circle key={trailer.id} center={[trailer.latitude, trailer.longitude]} radius={3}>
                    <Popup closeButton={false}>
                        <Text strong>Trailer #: </Text>{trailer.id}<br/>
                        <Text strong>Time: </Text>{!!trailer.time && moment(trailer.time).format('DD/MM/YYYY HH:mm:ss')} <br/>
                        <Text strong>Lat: </Text>{trailer.latitude} <Text strong>Long: </Text>{trailer.longitude}
                    </Popup>
                </Circle >
            )}
            {mode === "trip" && tripPositions.map(trailer => 
                <React.Fragment key={trailer.trip_id}>
                    {!!trailer.discharge_latitude && !!trailer.discharge_longitude && <Polyline pathOptions={{ color: '#e6c13c' }} positions={[trailer.path]} />}
                    <Circle key={trailer.trip_id + trailer.tag} center={[trailer.pickup_latitude, trailer.pickup_longitude]} radius={3} color="#e6833c">
                        <Popup closeButton={false}>
                            <Text strong>Action: </Text>PICKUP<br/>
                            <Text strong>Trip: </Text>{trailer.trip_id}<br/>
                            <Text strong>Tractor: </Text>{trailer.tag}<br/>
                            <Text strong>Trailer #: </Text>{trailer.trailer_number || "---"}<br/>
                            <Text strong>Pickup Time: </Text>{!!trailer.pickup_time && moment(trailer.pickup_time).format('DD/MM/YYYY HH:mm:ss')} <br/>
                            <Text strong>Lat: </Text>{trailer.pickup_latitude} <Text strong>Long: </Text>{trailer.pickup_longitude}
                        </Popup>
                    </Circle>
                    {!!trailer.discharge_latitude && !!trailer.discharge_longitude &&
                        <Circle key={trailer.trip_id + trailer.trailer_number} center={[trailer.discharge_latitude, trailer.discharge_longitude]} radius={3} color="#509423">
                            <Popup closeButton={false}>
                                <Text strong>Action: </Text>DISCHARGE<br/>
                                <Text strong>Trip: </Text>{trailer.trip_id}<br/>
                                <Text strong>Tractor: </Text>{trailer.tag}<br/>
                                <Text strong>Trailer #: </Text>{trailer.trailer_number || "---"}<br/>
                                <Text strong>Discharge Time: </Text>{!!trailer.discharge_time && moment(trailer.discharge_time).format('DD/MM/YYYY HH:mm:ss')} <br/>
                                <Text strong>Lat: </Text>{trailer.discharge_latitude} <Text strong>Long: </Text>{trailer.discharge_longitude}
                            </Popup>
                        </Circle>
                    }
                </React.Fragment>
            )}
        </MapContainer>
    )
}
