import React, { useState, useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import moment from 'moment';

import { MapContainer, TileLayer, Popup, Circle, ImageOverlay, Polyline } from 'react-leaflet'
import { GlobalOutlined } from "@ant-design/icons";
import { Button, Typography, Row, Col } from 'antd';
const {Text} = Typography;

// Map controls
const position = [51.9054, 4.3612]
const mapBounds = [[51.901503, 4.354982],[51.910094, 4.368867]]


export default function SingleTripMap(props){

    const [tripEvents, setTripEvents] = useState([]);
    const [polylineLocations, setPolylineLocations] = useState([])

    // Map switch
    const [showMap, toggleMap] = useState(false);

    useEffect(() => {
        let newTrip = []
        let newLocations = []
        props.tripEvents.map(row => {
            if (row.payload?.position?.latitude && row.payload?.position?.longitude) {
                newTrip.push(row)
                newLocations.push([row.payload.position.latitude, row.payload.position.longitude])
            }
        })
        setTripEvents(newTrip)
        setPolylineLocations(newLocations)
    }, [props.tripEvents])

    const toggleMapButton = 
        <Button 
            icon={<GlobalOutlined />}
            style={{width: 145}}
            onClick={e => toggleMap(show => {return !show})}
        >
            {showMap ? "Hide satelite" : "Show satelite"}
        </Button>

    const filtersBox = 
        <div id="filtersbox" style={{zIndex: 998, position: "absolute", top: 4, right: 4, width: 154, padding: 4}}>
            <Row gutter={4} type="flex" justify="space-between">
                <Col>
                    {toggleMapButton}
                </Col>
            </Row>
        </div>


    return (
        <MapContainer
            id="map_container"
            center={position}
            zoom={15}
            scrollWheelZoom={true}
            style={{height: '100%', width: '100%', minHeight: 250}}
        >
            {filtersBox}
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ImageOverlay id="map_sat" bounds={mapBounds} url='./images/BigMap.png' opacity={showMap ? 0.5 : 0.0}/>
            <Polyline pathOptions={{ color: '#e6c13c' }} positions={polylineLocations} />
            {tripEvents.map(event => 
                <Circle key={event.id} center={[event.payload?.position?.latitude, event.payload?.position?.longitude]} radius={3} color={event.message_type === "trailer/pickup" ? "#e6833c": event.message_type === "deck" ? "#777": event.message_type === "trailer/delivery" ? "#509423" : "#bbb"}>
                    <Popup closeButton={false}>
                        <Text strong>Action: </Text>
                            {event.message_type === "trailer/pickup" && "PICKUP"}
                            {event.message_type === "deck" && "DECK"}
                            {event.message_type === "trailer/delivery" && "DELIVERY"}
                        <br/>
                        <Text strong>Tractor: </Text>{event.tag}<br/>
                        <Text strong>Event ID: </Text>{event.id || "---"}<br/>
                        <Text strong>Time:</Text> {!!event.created ? moment(event.created).format('DD/MM/YYYY HH:mm:ss') : event.created} <br/>
                        <Text strong>Lat: </Text>{event.payload?.position?.latitude?.toFixed(6)} <Text strong>Long: </Text>{event.payload?.position?.longitude.toFixed(6)}
                    </Popup>
                </Circle>
                    // <Circle key={event.trip_id + event.event_number} center={[event.discharge_latitude, event.discharge_longitude]} radius={3} color="#509423">
                    //     <Popup>
                    //         <Text strong>Discharge Time: </Text>{!!event.discharge_time && moment(event.discharge_time).format('DD/MM/YYYY HH:mm:ss')} <br/>
                    //         <Text strong>Lat: </Text>{event.discharge_latitude} <Text strong>Long: </Text>{event.discharge_longitude}
                    //     </Popup>
                    // </Circle>
            )}
        </MapContainer>
    )
}
