import React from "react";
import moment from 'moment';

import { Typography, Tag, Table } from 'antd';
const { Text } = Typography;


export default function TripTable(props){

    const columns = [
        { title: 'Selected Trip', width: 180, render: (_val, _record) => props.tripSelectionBox },
        { title: 'Trip Info', width: 180, render: (_val, record) => 
            <>
                <Text strong>Trip ID: </Text><Text>{record.trip_id}</Text><br/>
                <Text strong>Tractor ID: </Text><Text>{record.tag}</Text>
            </>
        },
        { title: 'Created/Updated', width: 220, render: (_val, record) => 
            <>
                <Text strong>Created: </Text><Text>{!!record.created ? moment(record.created).format('DD/MM/YYYY HH:mm:ss') : record.created}</Text><br/>
                <Text strong>Updated: </Text><Text>{!!record.updated ? moment(record.updated).format('DD/MM/YYYY HH:mm:ss') : record.updated}</Text>
            </>
        },
        { title: 'Status', width: 110, render: (_val, record) => 
            <>
                {record.pickup_deck_id != null
                    ? record.discharge_deck_id != null  // pickup vessel
                        ? <Tag color="#4287f5">VESSEL SHIFT</Tag> // delivery vessel
                        : record.discharge_time != null
                            ? <Tag color="#509423">DISCHARGE</Tag>  // delivery yard
                            : <Tag color="#aaa">VESSEL PICKUP</Tag>  // not delivered
                    : record.discharge_deck_id != null  // pickup yard
                        ? <Tag color="#e6833c">LOAD</Tag>  // delivery vessel
                        : record.discharge_time != null
                            ? <Tag color="#666">YARD SHIFT</Tag>  // delivery yard
                            : <Tag color="#aaa">YARD PICKUP</Tag>  // not delivered
                }

            </>
        },
        { title: 'Pickup', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Deck ID: </Text><Text>{record.pickup_deck_id}</Text><br/>
                    <Text strong>Latitude: </Text><Text>{record.pickup_latitude}</Text><br/>
                    <Text strong>Longitude: </Text><Text>{record.pickup_longitude}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.pickup_time ? moment(record.pickup_time).format('DD/MM/YYYY HH:mm:ss') : record.pickup_time}</Text>
                </React.Fragment>
        },
        { title: 'Discharge', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Deck ID: </Text><Text>{record.discharge_deck_id}</Text><br/>
                    <Text strong>Latitude: </Text><Text>{record.discharge_latitude}</Text><br/>
                    <Text strong>Longitude: </Text><Text>{record.discharge_longitude}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.discharge_time ? moment(record.discharge_time).format('DD/MM/YYYY HH:mm:ss') : record.discharge_time}</Text>
                </React.Fragment>
        },
        { title: 'Trailer', width: 200, render: (_val, record) => 
                <React.Fragment>
                    <Text strong>Number: </Text><Text>{record.trailer_number}</Text><br/>
                    <Text strong>Time: </Text><Text>{!!record.trailer_number_time ? moment(record.trailer_number_time).format('DD/MM/YYYY HH:mm:ss') : record.trailer_number_time}</Text>
                </React.Fragment>
        },
    ];


    return (
        <Table
            columns={columns}
            size="small"
            loading={props.loading}
            pagination={false}
            bordered={true}
            rowKey="trip_id"
            dataSource={props.data}
            style={{overflowX: 'scroll'}}
        />
    )
}
